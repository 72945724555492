import React from 'react';
import './App.css';

var URL = window.URL || window.webkitURL

const LEFT_REPEATER_SUFFIX = '-left_repeater';
const FRONT_CAMERA_SUFFIX = '-front';
const RIGHT_REPEATER_SUFFIX = '-right_repeater';
const REAR_VIEW_SUFFIX = '-rear_view';
const REAR_VIEW_SUFFIX2 = '-back';


const displayTimestamp = function(timestamp, showSeconds) {
  const timestampsFromEvent = timestamp.split('_');

  if (timestampsFromEvent.length > 1) {
    const dateArray = timestampsFromEvent[0].split('-');
    const timeArray = timestampsFromEvent[1].split('-');
    let formattedTimestamp = dateArray[0] + '-' + dateArray[1] + '-' + dateArray[2] + ' ' + timeArray[0] + ':' + timeArray[1];
    if (showSeconds && (typeof timeArray[2] !== 'undefined')) {
      formattedTimestamp = formattedTimestamp + ':' + timeArray[2];
    }
    return formattedTimestamp;
  } else {
    return timestamp;
  }
}

const timestampListItem = function(timestamp, currentTimestamp, gotoTimestamp) {
  let className;
  if (timestamp === currentTimestamp) {
    className = 'current';
  } else {
    className = '';
  }

  return (
    <li key={timestamp.toString()} className={className}
      onClick={() => gotoTimestamp(timestamp)}>
      {displayTimestamp(timestamp)}
    </li>
  );
}

const TimestampList = function(props) {
  const timestamps = props.timestamps;
  const listItems = timestamps.map((timestamp) =>
    timestampListItem(timestamp.toString(), props.currentTimestamp, props.gotoTimestamp)
  );
  return (
    <div className="timestamp-list-container2">
      {timestamps.length > 0 &&
        <div>
          <h3>Video Timestamps</h3>
          <div id="timestamp-info">
            Click to jump to timestamp.
          </div>
          <div id="timestamp-list" className="timestamp-list">
            <ul>{listItems}</ul>
          </div>
        </div>
      }
    </div>
  );
}


const EventList = function(props) {
  const timestamps = props.orderedTimestamps;
  const changeEvent = props.changeEvent;
  const successMessage = props.successMessage;
  // console.log('EventList eventTimestampToPlay', props.eventTimestampToPlay);
  const listItems = timestamps.map((timestamp) =>
    <option key={timestamp.toString()} value={timestamp}>{displayTimestamp(timestamp, true)}</option>
  );

  const prevousEvent = function() {
    let selEventList = document.getElementById('select-event-list');
    let selectedIndex = selEventList.selectedIndex;
    let nbrOptions = selEventList.options.length;
    if (selectedIndex > 0 && selectedIndex < nbrOptions) {
      selEventList.selectedIndex = selEventList.selectedIndex - 1;
      changeEvent();
      window.gtag('event', 'prev_event', {
        'event_label': 'Previous event',
        'event_category': 'video_buttons'
      });
    } else {
      successMessage('No previous events');
    }
  }

  const nextEvent = function() {
    let selEventList = document.getElementById('select-event-list');
    let selectedIndex = selEventList.selectedIndex;
    let nbrOptions = selEventList.options.length;
    if (selectedIndex < nbrOptions-1) {
      selEventList.selectedIndex = selEventList.selectedIndex + 1;
      changeEvent();
      window.gtag('event', 'next_event', {
        'event_label': 'Next event',
        'event_category': 'video_buttons'
      });
    } else {
      successMessage('No more events');
    }  
  };

  return (
    <div>
      <div className="text-center">
        Select Event to Play:
        <select id="select-event-list" name="select-event-list" onChange={props.changeEvent}>
          {listItems}
        </select>
      </div>
      <div className="text-center">
        <button id="previous-event-btn" type="button" className="btn btn-success" onClick={prevousEvent}>&lt; Previous</button>
        &nbsp;
        <button id="next-event-btn" type="button" className="btn btn-success" onClick={nextEvent}>Next &gt;</button> 
      </div>
    </div>
  );
}


const playPause = function() { 
  const button = document.getElementById('play-pause-btn');
  let videoNodeLeft = document.querySelector('#video-left');
  let videoNodeFront = document.querySelector('#video-front');
  let videoNodeRight = document.querySelector('#video-right');
  let videoNodeRear = document.querySelector('#video-rearview');

  if (videoNodeFront.paused) {
      videoNodeLeft.play();
      videoNodeFront.play();
      videoNodeRight.play();
      videoNodeRear.play();
      button.innerHTML = 'Pause';
  } else {
      videoNodeLeft.pause();
      videoNodeFront.pause();
      videoNodeRight.pause();
      videoNodeRear.pause();
      button.innerHTML = 'Play';
  }
}


class VideoControls extends React.Component {
  
  componentDidUpdate(prevProps) {
    if (document.getElementById('seek-bar')) {
      document.getElementById('seek-bar').addEventListener("input", this.props.seekBarInputChanged);
      document.getElementById('seek-bar').addEventListener("change", this.props.seekBarChanged);
    }
    if (this.props.getTimestamps().length > 0 && !document.querySelector('#video-front').paused) {
      document.getElementById('play-pause-btn').innerHTML = 'Pause';
    }
  }
  
  render() {
    return (
      <div>
        {this.props.getTimestamps().length > 0 &&
          <div className="row justify-content-md-center"> 
            <div className="col-sm-4">
              <EventList 
                orderedTimestamps={this.props.savedEventsObj.orderedTimestamps} 
                eventTimestampToPlay={this.props.eventTimestampToPlay}
                changeEvent={this.props.changeEvent}
                successMessage={this.props.successMessage} />
            </div>
            <div className="col-sm-8">
              <div id="control-buttons">
                <button id="play-pause-btn" type="button" className="btn btn-light" onClick={playPause}>Pause</button> 
                <button type="button" className="btn btn-light" id="btn-change-speed-05" 
                  title="Click to change playback speed to 0.5"
                  onClick={() => this.props.changePlaybackRate(0.5)}>0.5x</button>
                <button type="button" className="btn btn-primary" id="btn-change-speed-1" 
                  title="Click to change playback speed to normal"
                  onClick={() => this.props.changePlaybackRate(1)}>1x</button>
                <button type="button" className="btn btn-light" id="btn-change-speed-3" 
                  title="Click to change playback speed to 3x"
                  onClick={() => this.props.changePlaybackRate(3)}>3x</button>
                <button type="button" className="btn btn-light" id="btn-change-speed-10" 
                  title="Click to change playback speed to 10x"
                  onClick={() => this.props.changePlaybackRate(10)}>10x</button>
                {this.props.nextToLastTimestamp !== '' &&
                  <button type="button" className="btn btn-light"
                    onClick={() => this.props.jumpToEventTimestamp(this.props.nextToLastTimestamp)}>Jump to Event</button>
                }
                <span>Seconds: <span id="currentTime"></span></span>
                <input type="range" id="seek-bar" defaultValue="0" /> 
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}


class VideoContainer extends React.Component {
  constructor(props) {
    super(props);
    // Set initial state
    this.state = {
      currentTimestamp: '',
      currentTimestampIndex: 0,
      playbackRate: 1,
      timestamps: [],
      leftRepeaterMap: new Map(),
      frontMap: new Map(),
      rightRepeaterMap: new Map(),
      nextToLastTimestamp: '',
      hasFourCameras: false
    }
    this.getCurrentTimestamp = this.getCurrentTimestamp.bind(this);
    this.getTimestamps = this.getTimestamps.bind(this);
    this.loadDirectories = this.loadDirectories.bind(this);
    this.loadFiles = this.loadFiles.bind(this);
    this.loadSelectedFiles = this.loadSelectedFiles.bind(this);
    this.updateSeekBar = this.updateSeekBar.bind(this);
    this.seekBarInputChanged = this.seekBarInputChanged.bind(this);
    this.seekBarChanged = this.seekBarChanged.bind(this);
    this.gotoTimestamp = this.gotoTimestamp.bind(this);
    this.changePlaybackRate = this.changePlaybackRate.bind(this);
    this.jumpToEventTimestamp = this.jumpToEventTimestamp.bind(this);
    this.changeEvent = this.changeEvent.bind(this);
    this.successMessage = this.successMessage.bind(this);
  }

  changeEvent = function() {
    console.log('In changeEvent...');
    let selEventList = document.getElementById('select-event-list');
    let selectedEventTime = selEventList.options[selEventList.selectedIndex].value;
    console.log('selectedEventTime', selectedEventTime);

    this.setState({
      eventTimestampToPlay: selectedEventTime
    });
    this.loadEvent(selectedEventTime);
  }

  loadEvent = function(eventTime) {
    console.log('In loadEvent...');
    console.log('eventTime', eventTime);
    console.log('this.state.savedEventsObj', this.state.savedEventsObj);

    this.playEvent(this.state.savedEventsObj, eventTime)
  }

  successMessage = function(message) {
    let successMessage = document.getElementById('success-message');
    successMessage.innerHTML = message;
    successMessage.classList.add('show');
    setTimeout(function(){ 
      successMessage.classList.remove('show');
    }, 4000);
  }

  jumpToEventTimestamp = function(nextToLastTimestamp) {
    console.log('In jumpToEventTimestamp...');
    console.log('nextToLastTimestamp', nextToLastTimestamp);
    console.log('this.state.previousTime', this.state.previousTime);

    let timestamps = this.state.timestamps;
    let numberOfTimestamps = timestamps.length;
    console.log('numberOfTimestamps-2', numberOfTimestamps-2);

    window.gtag('event', 'jump_to_event', {
      'event_label': 'Jump to event',
      'event_category': 'video_buttons'
    });

    let currentTimestampIndex = numberOfTimestamps-2;
    this.setState({
      currentTimestamp: nextToLastTimestamp,
      currentTimestampIndex: currentTimestampIndex
    });

    // Scroll down timestamp list
    var timestampList = document.getElementById("timestamp-list");
    timestampList.scrollTop = timestampList.scrollHeight;

    this.successMessage('Jumping to ' + nextToLastTimestamp + ' and estimating time of event.');
    this.loadVideos(timestamps, currentTimestampIndex);

    // Go to time
    let videoNodeLeft = document.querySelector('#video-left');
    let videoNodeFront = document.querySelector('#video-front');
    let videoNodeRight = document.querySelector('#video-right');
    const sentryEventTime = this.state.previousTime;
    console.log('Go to specific time...', sentryEventTime);

    if (sentryEventTime) {
      let interval = setInterval(function() {
        // console.log('videoNodeLeft.readyState=' + videoNodeLeft.readyState);
        if(videoNodeLeft.readyState > 0 && videoNodeFront.readyState > 0 && videoNodeRight.readyState > 0) {    
          clearInterval(interval);
          console.log('Jumping to sentryEventTime... ' + sentryEventTime);
          videoNodeLeft.currentTime = sentryEventTime;
          videoNodeFront.currentTime = sentryEventTime;
          videoNodeRight.currentTime = sentryEventTime;
        }
      }, 200);
    }
  }

  changePlaybackRate = function(newPlaybackRate) {
    this.setState({playbackRate: newPlaybackRate});

    let strPlaybackRate = '' + newPlaybackRate; // convert to string
    if (newPlaybackRate === 0.5) {
      strPlaybackRate = '05';
    }
    
    let videoNodeLeft = document.querySelector('#video-left');
    let videoNodeFront = document.querySelector('#video-front');
    let videoNodeRight = document.querySelector('#video-right');
    let videoNodeRear = document.querySelector('#video-rearview');
    
    const buttonIds = ['btn-change-speed-05', 'btn-change-speed-1', 'btn-change-speed-3', 'btn-change-speed-10'];
    for (let i = 0; i < buttonIds.length; i++) {
      let btn = document.getElementById(buttonIds[i]);
      let splitId = buttonIds[i].split('-');
      if (splitId[splitId.length-1] === strPlaybackRate) {
        btn.classList.add("btn-primary");
        btn.classList.remove("btn-light");
      } else {
        btn.classList.add("btn-light");
        btn.classList.remove("btn-primary");  
      }
    }
  
    videoNodeLeft.playbackRate = newPlaybackRate;
    videoNodeFront.playbackRate = newPlaybackRate;
    videoNodeRight.playbackRate = newPlaybackRate;
    videoNodeRear.playbackRate = newPlaybackRate;

    window.gtag('event', 'video_speed', {
      'event_label': 'Video speed ' + strPlaybackRate,
      'event_category': 'video_speed'
    });
  }

  startNextVideo = function() {
    console.log('In startNextVideo...');
    console.log(this.state.timestamps, this.state.currentTimestampIndex);
    
    let timestamps = this.state.timestamps;
    let currentTimestampIndex = this.state.currentTimestampIndex;

    // Check if next timestamp available
    let numberOfTimestamps = timestamps.length;
    if (currentTimestampIndex < numberOfTimestamps - 1) {
      let nextTimestampIndex = currentTimestampIndex + 1;
      let nextTimestamp = timestamps[nextTimestampIndex];
      
      console.log('Launch next videos...');
      console.log(nextTimestamp, nextTimestampIndex);

      this.setState({
        currentTimestamp: nextTimestamp,
        currentTimestampIndex: nextTimestampIndex
      });
      
      this.loadVideos(timestamps, nextTimestampIndex);
    } else {
      console.log('This was last index of timestamps');

      // See if next event available to play
      let savedEventsObj = this.state.savedEventsObj;
      let eventTimestampToPlay = this.state.eventTimestampToPlay;
      let totalEventTimestamps = savedEventsObj.orderedTimestamps.length;
      let currentEventIndex = savedEventsObj.orderedTimestamps.indexOf(eventTimestampToPlay);
      console.log('totalEventTimestamps', totalEventTimestamps);
      console.log('currentEventIndex', currentEventIndex);

      if (currentEventIndex > -1 && currentEventIndex < totalEventTimestamps - 1) {
        console.log('There are more events');
        let nextEventTimestamp = savedEventsObj.orderedTimestamps[currentEventIndex + 1];
        console.log('nextEventTimestamp', nextEventTimestamp);

        // Save events to state
        this.setState({
          eventTimestampToPlay: nextEventTimestamp
        });

        // Update select input to new timestamp
        let selEventList = document.getElementById('select-event-list');
        var opts = selEventList.options;
        for (var i = 0; i < opts.length; i++) {
          if (opts[i].value === nextEventTimestamp) {
            selEventList.selectedIndex = i;
            break;
          }
        }
        this.playEvent(savedEventsObj, nextEventTimestamp);    
      } else {
        this.successMessage('End of video events');
      }
    }
  }
  
  updateSeekBar = function() {
    let videoNodeFront = document.querySelector('#video-front');
    document.querySelector('#currentTime').innerHTML = videoNodeFront.currentTime.toFixed(1);
    let seekBar = document.querySelector('#seek-bar');
    var value = (100 / videoNodeFront.duration) * videoNodeFront.currentTime;
    seekBar.value = value;
  
    if (value >= 99.9) {
      this.startNextVideo();
    }
  }

  seekBarChanged = function() {
    let seekBar = document.getElementById('seek-bar');
    let videoNodeLeft = document.getElementById('video-left');
    let videoNodeFront = document.getElementById('video-front');
    let videoNodeRight = document.getElementById('video-right');
    let videoNodeRear = document.getElementById('video-rearview');
    let time = videoNodeFront.duration * (seekBar.value / 100);

    videoNodeLeft.currentTime = time;
    videoNodeFront.currentTime = time;
    videoNodeRight.currentTime = time;
    videoNodeRear.currentTime = time;

    try {
      if (videoNodeLeft.readyState > 0) {
        videoNodeLeft.play();
      }
      if (videoNodeFront.readyState > 0) {
        videoNodeFront.play();
      }
      if (videoNodeRight.readyState > 0) {
        videoNodeRight.play();
      }
      if (videoNodeRear.readyState > 0) {
        videoNodeRear.play();
      }
    } catch(err) {
      console.error(err);
    }
    

    document.getElementById('play-pause-btn').innerHTML = 'Pause';
  }

  seekBarInputChanged = function() {
    let seekBar = document.getElementById('seek-bar');
    
    let videoNodeLeft = document.getElementById('video-left');
    let videoNodeFront = document.getElementById('video-front');
    let videoNodeRight = document.getElementById('video-right');
    let videoNodeRear = document.getElementById('video-rearview');

    if (!videoNodeFront.paused) {
      videoNodeLeft.pause();
      videoNodeFront.pause();
      videoNodeRight.pause();
      videoNodeRear.pause();
    }

    let time = videoNodeFront.duration * (seekBar.value / 100);
    videoNodeLeft.currentTime = time;
    videoNodeFront.currentTime = time;
    videoNodeRight.currentTime = time;
    videoNodeRear.currentTime = time;

    document.getElementById('play-pause-btn').innerHTML = 'Play';
  }

  getTimestamps = function() {
    return this.state.timestamps;
  }

  getCurrentTimestamp = function() {
    return this.state.currentTimestamp;
  }


  loadDirectories = function(event) {
    this.loadSelectedFiles(event, 'input-directory');
  }

  loadFiles = function(event) {
    this.loadSelectedFiles(event, 'input-file');
  }

  scrollToVideoPlayer = function() {
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop
    if ((window.screen.height < 420 && scrollTop < 400) 
      || (window.screen.height < 740 && scrollTop < 400)
      || (window.screen.height >= 740 && scrollTop < 300)) {
      let scrollToPoint = document.getElementById('success-message');
      scrollToPoint.scrollIntoView();
    }
  }


  loadSelectedFiles = function (event, inputId) {
    console.log('In loadSelectedFiles... inputId=' + inputId);
    let hasFourCameras = false;
    
    // Scroll window if video section may not be visible
    this.scrollToVideoPlayer();

    // Reset values
    this.setState(state => ({
      timestamps: [],
      currentTimestamp: '',
      currentTimestampIndex: 0
    }));

    let savedEventsObj = {orderedTimestamps: [], events: {}};
    let hasNestedDirectories = false;
    if (inputId === 'input-directory') {
      hasNestedDirectories = true;
    }

    // Loop thru all files - put in separate arrays by cam location
    let files = document.getElementById(inputId).files;
    let i;
    let numberOfFiles = files.length;
    let eventTimestamp = 'single-event';
    console.log('numberOfFiles', numberOfFiles);

    for (i = 0; i < numberOfFiles; i++) { 
      // Only handle mp4 files
      if (!files[i].name.endsWith('.mp4')) {
        continue;
      }

      if (hasNestedDirectories) {
        let splitDirectories = files[i].webkitRelativePath.split('/');
        eventTimestamp = splitDirectories[splitDirectories.length - 2];
        if (typeof savedEventsObj.events[eventTimestamp] === 'undefined') {
          // new object
          savedEventsObj.events[eventTimestamp] = {
            timestampsFromEvent: [],
            leftRepeaterMap: new Map(), 
            frontMap: new Map(), 
            rightRepeaterMap: new Map(),
            rearViewMap: new Map()
          };
        }
      } else {
        // Single event
        if (typeof savedEventsObj.events[eventTimestamp] === 'undefined') {
          eventTimestamp = files[i].name.replace(LEFT_REPEATER_SUFFIX + '.mp4', '')
            .replace(FRONT_CAMERA_SUFFIX + '.mp4', '')
            .replace(RIGHT_REPEATER_SUFFIX + '.mp4', '')
            .replace(REAR_VIEW_SUFFIX + '.mp4', '')
            .replace(REAR_VIEW_SUFFIX2 + '.mp4', '');
          
            // new object
          savedEventsObj.events[eventTimestamp] = {
            timestampsFromEvent: [],
            leftRepeaterMap: new Map(), 
            frontMap: new Map(), 
            rightRepeaterMap: new Map(),
            rearViewMap: new Map()
          };
        }
      }

      if (files[i].name.indexOf('left_repeater') >= 0) {
        savedEventsObj.events[eventTimestamp].leftRepeaterMap.set('' + files[i].name, files[i]);
      } else if (files[i].name.indexOf('front') >= 0) {
        savedEventsObj.events[eventTimestamp].frontMap.set('' + files[i].name, files[i]);
      } else if (files[i].name.indexOf('right_repeater') >= 0) {
        savedEventsObj.events[eventTimestamp].rightRepeaterMap.set('' + files[i].name, files[i]);
      } else if (files[i].name.indexOf('rear_view') >= 0 || files[i].name.indexOf('back') >= 0) {
        console.log('Adding rear view camera: ' + files[i].name);
        hasFourCameras = true;
        savedEventsObj.events[eventTimestamp].rearViewMap.set('' + files[i].name, files[i]);
      }
    }

    // Create ordered list of eventTimestamps
    let orderedTimestamps = Object.keys(savedEventsObj.events).sort();
    savedEventsObj.orderedTimestamps = orderedTimestamps;
    console.log('savedEventsObj', savedEventsObj);

    // Get first event
    let eventTimestampToPlay = savedEventsObj.orderedTimestamps[0];

    // Save events to state
    this.setState({
      savedEventsObj: savedEventsObj,
      eventTimestampToPlay: eventTimestampToPlay,
      hasFourCameras: hasFourCameras
    });

    if (hasFourCameras) {
      window.gtag('event', 'cameras', {
        'event_label': '4 cameras',
        'event_category': 'camera'
      });
      // this.useFourCameraView();
      // document.getElementById('video-rear-container').classList.remove('hide');
    } else {
      window.gtag('event', 'cameras', {
        'event_label': '3 cameras',
        'event_category': 'camera'
      });
    }

    this.playEvent(savedEventsObj, eventTimestampToPlay);
  }


  playEvent = function(savedEventsObj, eventTimestampToPlay) {
    console.log('In playEvent... eventTimestampToPlay=' + eventTimestampToPlay);
    
    let videoNodeLeft = document.getElementById('video-left');
    let videoNodeFront = document.getElementById('video-front');
    let videoNodeRight = document.getElementById('video-right');
    let videoNodeRear = document.getElementById('video-rearview');

    // Play event
    let eventToPlay = savedEventsObj.events[eventTimestampToPlay];
    console.log('eventToPlay', eventToPlay);
    let timestampsFromEvent = [];

    // Exit if eventToPlay doesn't exist
    if (typeof eventToPlay === 'undefined') {
      console.log('eventToPlay does not exist... exiting playEvent');
      return;
    }
    
    // Perf improvement: Create array of timestampsFromEvent at load time
    for (let key of eventToPlay.leftRepeaterMap.keys()) {
        let timestamp = key.replace(LEFT_REPEATER_SUFFIX + '.mp4', '');
        timestampsFromEvent.push(timestamp);
    }

    // If left camera files don't exist
    if (timestampsFromEvent.length === 0) {
      for (let key of eventToPlay.frontMap.keys()) {
        let timestamp = key.replace(FRONT_CAMERA_SUFFIX + '.mp4', '');
        timestampsFromEvent.push(timestamp);
      }
    }

    // If front camera files also don't exist
    if (timestampsFromEvent.length === 0) {
      for (let key of eventToPlay.rightRepeaterMap.keys()) {
        let timestamp = key.replace(RIGHT_REPEATER_SUFFIX + '.mp4', '');
        timestampsFromEvent.push(timestamp);
      }
    }

    // Sort timestamps from event
    timestampsFromEvent.sort();
    console.log('timestampsFromEvent', timestampsFromEvent);

    let currentTimestamp = timestampsFromEvent[0];
    console.log('currentTimestamp', currentTimestamp);
    
    // TODO: See why getting error that currentTime innerHTML doesn't exist when removing following setState
    this.setState(state => ({
      timestamps: timestampsFromEvent,
      currentTimestamp: currentTimestamp,
      currentTimestampIndex: 0
    }));
    
    // Get videos to play from event
    var leftRepeaterFile = eventToPlay.leftRepeaterMap.get(currentTimestamp + LEFT_REPEATER_SUFFIX + '.mp4');
    var frontFile = eventToPlay.frontMap.get(currentTimestamp + FRONT_CAMERA_SUFFIX + '.mp4');
    var rightRepeaterFile = eventToPlay.rightRepeaterMap.get(currentTimestamp + RIGHT_REPEATER_SUFFIX + '.mp4');
    var rearViewFile;

    if (eventToPlay.rearViewMap.has(currentTimestamp + REAR_VIEW_SUFFIX + '.mp4')) {
      rearViewFile = eventToPlay.rearViewMap.get(currentTimestamp + REAR_VIEW_SUFFIX + '.mp4');
    } else if (eventToPlay.rearViewMap.has(currentTimestamp + REAR_VIEW_SUFFIX2 + '.mp4')) {
      rearViewFile = eventToPlay.rearViewMap.get(currentTimestamp + REAR_VIEW_SUFFIX2 + '.mp4');
    }

    let playbackRate = this.state.playbackRate;
    console.log('playbackRate', playbackRate);

    if (leftRepeaterFile) {
      let leftRepeaterFileURL = URL.createObjectURL(leftRepeaterFile);
      videoNodeLeft.src = leftRepeaterFileURL;
      videoNodeLeft.playbackRate = playbackRate;
      document.getElementById('video-left-title').innerHTML = leftRepeaterFile.name;
    }
    
    if (frontFile) {
      let frontFileURL = URL.createObjectURL(frontFile);
      videoNodeFront.src = frontFileURL;
      videoNodeFront.playbackRate = playbackRate;
      document.getElementById('video-front-title').innerHTML = frontFile.name;
    }
    
    if (rightRepeaterFile) {
      let rightRepeaterFileURL = URL.createObjectURL(rightRepeaterFile);
      videoNodeRight.src = rightRepeaterFileURL;
      videoNodeRight.playbackRate = playbackRate;
      document.getElementById('video-right-title').innerHTML = rightRepeaterFile.name;
    }

    if (rearViewFile) {
      console.log('*** rearViewFile to play', rearViewFile);
      // TODO: Add to video element
      let rearViewFileURL = URL.createObjectURL(rearViewFile);
      videoNodeRear.src = rearViewFileURL;
      videoNodeRear.playbackRate = playbackRate;
      document.getElementById('video-rearview-title').innerHTML = rearViewFile.name;
    } else {
      // Clear rear camera when going to event that doesn't have that camera
      videoNodeRear.src = '';
      document.getElementById('video-rearview-title').innerHTML = 'Rear camera (not found)';
    }

    // Set height to keep video windows from bouncing between video loads
    setTimeout(function(){ 
      let height = document.getElementById('video-left-holder').offsetHeight;
      document.getElementById('video-left-holder').style.height = height + "px";
    }, 2000);

    // TODO: Check if video is actually playing
    // videoNodeLeft.currentTime

    // var isChromium = window.chrome;
    // var winNav = window.navigator;
    // var vendorName = winNav.vendor;
    // var isOpera = typeof window.opr !== "undefined";
    // var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    // var isIOSChrome = winNav.userAgent.match("CriOS");

    // if (isIOSChrome) {
    //   // is Google Chrome on IOS
    // } else if(
    //   isChromium !== null &&
    //   typeof isChromium !== "undefined" &&
    //   vendorName === "Google Inc." &&
    //   isOpera === false &&
    //   isIEedge === false
    // ) {
    //   // is Google Chrome
    // } else { 
    //   // not Google Chrome 
    // }


    // Estimate Sentry Event time
    this.estimateSentryEventTime(eventToPlay.frontMap, timestampsFromEvent)
  }

  estimateSentryEventTime = function(frontMap, timestampsFromEvent) {
    console.log('In estimateSentryEventTime...');
    if (frontMap.size >= 10 && frontMap.size < 12) {
      // Get last timestamp in timestampsFromEvent
      let lastTimestamp = timestampsFromEvent[timestampsFromEvent.length - 1];
      let nextToLastTimestamp = timestampsFromEvent[timestampsFromEvent.length - 2];

      this.setState(state => ({
        lastTimestamp: lastTimestamp,
        nextToLastTimestamp: nextToLastTimestamp
      }));

      var lastFile = frontMap.get(lastTimestamp + FRONT_CAMERA_SUFFIX + '.mp4');

      // Load video into #video-temp
      let tempVideo = document.querySelector('#video-temp');
      let lastFileURL = URL.createObjectURL(lastFile);
      tempVideo.src = lastFileURL;

      // Get duration of last file
      const _this = this;
      let interval = setInterval(function() {
        console.log('tempVideo.readyState', tempVideo.readyState);
        if(tempVideo.readyState > 0) {
          let seconds = tempVideo.duration % 60;
          let lastFileDuration = Math.ceil(seconds);
          let previousTime = lastFileDuration > 2 ? lastFileDuration - 2 : 0;
          
          clearInterval(interval);
          _this.setState(state => ({
            previousTime: previousTime,
          }));
        }
      }, 200);

    } else {
      console.log('Number of videos is outside range of normal Sentry event');
      this.setState(state => ({
        lastTimestamp: '',
        nextToLastTimestamp: ''
      }));
    }
  }

  loadVideos = function(timestamps, currentTimestampIndex) {
    console.log('>> In loadVideos...');
    let timestamp = timestamps[currentTimestampIndex];
    console.log('timestamp', timestamp);
    let playbackRate = this.state.playbackRate;

    // Get current event from state
    let savedEventsObj = this.state.savedEventsObj;
    let eventTimestampToPlay = this.state.eventTimestampToPlay;
    let eventToPlay = savedEventsObj.events[eventTimestampToPlay];
    
    let leftRepeaterFile = eventToPlay.leftRepeaterMap.get(timestamp + LEFT_REPEATER_SUFFIX + '.mp4');
    let frontFile = eventToPlay.frontMap.get(timestamp + FRONT_CAMERA_SUFFIX + '.mp4');
    let rightRepeaterFile = eventToPlay.rightRepeaterMap.get(timestamp + RIGHT_REPEATER_SUFFIX + '.mp4');
    let rearViewFile;

    if (eventToPlay.rearViewMap.has(timestamp + REAR_VIEW_SUFFIX + '.mp4')) {
      rearViewFile = eventToPlay.rearViewMap.get(timestamp + REAR_VIEW_SUFFIX + '.mp4');
    } else if (eventToPlay.rearViewMap.has(timestamp + REAR_VIEW_SUFFIX2 + '.mp4')) {
      rearViewFile = eventToPlay.rearViewMap.get(timestamp + REAR_VIEW_SUFFIX2 + '.mp4');
    }

    // TODO: See why file is undefined - WRONG eventToPlay
    console.log('leftRepeaterFile', leftRepeaterFile);
    console.log('eventToPlay.leftRepeaterMap', eventToPlay.leftRepeaterMap);
    
    let videoNodeLeft = document.getElementById('video-left');
    let videoNodeFront = document.getElementById('video-front');
    let videoNodeRight = document.getElementById('video-right');
    let videoNodeRear = document.getElementById('video-rearview');
    
    if (leftRepeaterFile) {
      let leftRepeaterFileURL = URL.createObjectURL(leftRepeaterFile);
      videoNodeLeft.src = leftRepeaterFileURL;
      videoNodeLeft.playbackRate = playbackRate;
      document.getElementById('video-left-title').innerHTML = leftRepeaterFile.name;
    }
    
    if (frontFile) {
      let frontFileURL = URL.createObjectURL(frontFile);
      videoNodeFront.src = frontFileURL;
      videoNodeFront.playbackRate = playbackRate;
      document.getElementById('video-front-title').innerHTML = frontFile.name;
    }
    
    if (rightRepeaterFile) {
      let rightRepeaterFileURL = URL.createObjectURL(rightRepeaterFile);
      videoNodeRight.src = rightRepeaterFileURL;
      videoNodeRight.playbackRate = playbackRate;
      document.getElementById('video-right-title').innerHTML = rightRepeaterFile.name;
    }

    if (rearViewFile) {
      console.log('>>> rearViewFile to play', rearViewFile);
      // TODO: Add to video element
      let rearViewFileURL = URL.createObjectURL(rearViewFile);
      videoNodeRear.src = rearViewFileURL;
      videoNodeRear.playbackRate = playbackRate;
      document.getElementById('video-rearview-title').innerHTML = rearViewFile.name;
    } else {
      videoNodeRear.src = '';
      document.getElementById('video-rearview-title').innerHTML = 'Rear camera (not found)';
    }
  }


  gotoTimestamp = function(timestamp) {
    console.log('>>> GOTO timestamp:', timestamp);

    let timestamps = this.state.timestamps;
    let numberOfTimestamps = timestamps.length;
    
    let i;
    let index = 0;
    for (i = 0; i < numberOfTimestamps; i++) {
      if (timestamps[i] === timestamp) {
        index = i;
        break;
      }
    }
    
    this.setState({
      currentTimestamp: timestamp,
      currentTimestampIndex: index
    }, () => {
      this.loadVideos(timestamps, index);
    });
  }

  // useFourCameraView = function() {
  //   console.log('In useFourCameraView...');
  //   let leftVideoHolder = document.getElementById('video-left-holder');
  //   let frontVideoHolder = document.getElementById('video-front-holder');
  //   let rightVideoHolder = document.getElementById('video-right-holder');
  //   let rearVideoHolder = document.getElementById('video-rearview-holder');

  //   let fourCamLeftContainer = document.getElementById('fourcamera-left-container');
  //   let fourCamFrontContainer = document.getElementById('fourcamera-front-container');
  //   let fourCamRightContainer = document.getElementById('fourcamera-right-container');
  //   let fourCamRearContainer = document.getElementById('fourcamera-rear-container');

  //   fourCamLeftContainer.appendChild(leftVideoHolder);
  //   fourCamFrontContainer.appendChild(frontVideoHolder);
  //   fourCamRightContainer.appendChild(rightVideoHolder);
  //   fourCamRearContainer.appendChild(rearVideoHolder);
  // }

  resizeVideo = function(e) {
    console.log(e.target, 'In resizeVideo...');

    // Clear fixed height
    document.getElementById('video-left-holder').style.height = "";

    document.getElementById('primary-view-container').style.display = 'block';
    
    let primaryElement, secondElement, thirdElement, fourthElement;

    let leftVideoHolder = document.getElementById('video-left-holder');
    let frontVideoHolder = document.getElementById('video-front-holder');
    let rightVideoHolder = document.getElementById('video-right-holder');
    let rearVideoHolder = document.getElementById('video-rearview-holder');

    console.log('e.target.parentNode.id=' + e.target.parentNode.id);
    console.log('e.target.parentNode.parentNode.id=' + e.target.parentNode.parentNode.id);
    
    if (e.target.parentNode.parentNode.id === 'primary-view') {
      console.log('In secondary - switch to default...');
      document.getElementById('default-view-container').style.display = '';

      let defaultLeftContainer = document.getElementById('video-left-container');
      let defaultFrontContainer = document.getElementById('video-front-container');
      let defaultRightContainer = document.getElementById('video-right-container');
      let defaultRearContainer = document.getElementById('video-rear-container');

      defaultLeftContainer.appendChild(leftVideoHolder);
      defaultFrontContainer.appendChild(frontVideoHolder);
      defaultRightContainer.appendChild(rightVideoHolder);
      defaultRearContainer.appendChild(rearVideoHolder);
      
      document.getElementById('primary-view-container').style.display = 'none';

      // Set height to keep video windows from bouncing between video loads
      setTimeout(function(){ 
        let height = document.getElementById('video-left-holder').offsetHeight;
        document.getElementById('video-left-holder').style.height = height + "px";
      }, 2000);
      
      return;
    }

    if (e.target.id === 'video-left') {
      console.log('Make left primary');
      primaryElement = leftVideoHolder;
      secondElement = frontVideoHolder;
      thirdElement = rightVideoHolder;
      fourthElement = rearVideoHolder;
    } else if (e.target.id === 'video-right') {
      console.log('Make right primary');
      primaryElement = rightVideoHolder;
      secondElement = leftVideoHolder;
      thirdElement = frontVideoHolder;
      fourthElement = rearVideoHolder;
    } else if (e.target.id === 'video-rearview') {
      console.log('Make rearview primary');
      primaryElement = rearVideoHolder;
      secondElement = frontVideoHolder;
      thirdElement = leftVideoHolder;
      fourthElement = rightVideoHolder;
    } else {
      console.log('Make front primary');
      primaryElement = frontVideoHolder;
      secondElement = leftVideoHolder;
      thirdElement = rightVideoHolder;
      fourthElement = rearVideoHolder;
    }

    document.getElementById('primary-view').appendChild(primaryElement);
    document.getElementById('secondary-view-1').appendChild(secondElement);
    document.getElementById('secondary-view-2').appendChild(thirdElement);
    document.getElementById('secondary-view-3').appendChild(fourthElement);
    
    // Hide other empty views
    document.getElementById('default-view-container').style.display = 'none';
  }


  componentDidMount() {
    document.querySelector('#video-front').addEventListener("timeupdate", this.updateSeekBar);
  }

  checkForMultiDirectoryCapability() {
    var inputCheck = document.createElement("input");
    if(typeof inputCheck.webkitdirectory === "boolean") {
      return true;
    } else {
      return false;
    }
  }

  isMobile() {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      console.log('isMobile');
      return true;
    }
    return false;
  }

  isChrome() {
    var isChromium = window.chrome;
    var winNav = window.navigator;
    var vendorName = winNav.vendor;
    var isOpera = typeof window.opr !== "undefined";
    var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
    var isIOSChrome = winNav.userAgent.match("CriOS");

    if (isIOSChrome) {
      // is Google Chrome on IOS
      return true;
    } else if(
      isChromium !== null &&
      typeof isChromium !== "undefined" &&
      vendorName === "Google Inc." &&
      isOpera === false &&
      isIEedge === false
    ) {
      // is Google Chrome
      return true;
    } else { 
      // not Google Chrome 
      return false;
    }
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-8">
            <h2>Load Videos Here:</h2>
            {this.isChrome() &&
              <div className="alert alert-warning text-center" role="alert">
                Chrome browsers may have issues playing latest TeslaCam videos.<br/>
                If videos remain black, use Safari or Firefox browsers.
              </div>
            }
            <div className="card-group">
              { !this.isMobile() && 
                <div className="card text-white bg-custom">
                  <div className="card-header">Select Directory</div>
                  <div className="card-body text-dark bg-light file-picker-info">
                    <p className="card-text">
                      Select "TeslaCam" or one of the subdirectories to load ALL recorded events.
                    </p>
                    <small className="text-muted">
                      Some browsers may show an additional security prompt verifying you trust the site before "uploading".
                      Files are not uploaded.  Directory structure of any subdirectories are used for grouping events.
                    </small>
                  </div>
                  <div className="card-footer">
                    <input id="input-directory" type="file" webkitdirectory="true" multiple accept="video/mp4,video/x-m4v,video/*" 
                      onChange={this.loadDirectories} />
                  </div>
                </div>
              }
              {this.isMobile() &&
                <div className="card text-white bg-secondary">
                  <div className="card-header">Select Multiple Files in a Directory</div>
                  <div className="card-body text-dark bg-light file-picker-info">
                    <p className="card-text">
                      Select all files within one directory.
                    </p>
                    <small className="text-muted">
                      This was the previous functionality and can be used if the browser doesn't support selecting multiple directories.
                      Mobile browsers do not support selecting all and will see this File open dialog instead.<br/>
                      Windows Ctrl-A or Mac Cmd-A to select all files within a directory.
                    </small>
                  </div>
                  <div className="card-footer">
                    <input id="input-file" type="file" multiple accept="video/mp4,video/x-m4v,video/*" 
                      onChange={this.loadFiles} />
                  </div>
                </div>
              }
            </div>

            <div id="success-message" className="alert alert-success fade" role="alert">
              Message
            </div>
          </div>
          <div className="col-sm-3 timestamp-list-container">
            <TimestampList 
              timestamps={this.state.timestamps}
              currentTimestamp={this.state.currentTimestamp}
              gotoTimestamp={this.gotoTimestamp} />
          </div>
        </div>

        <VideoControls 
          timestamps={this.state.timestamps} 
          playbackRate={this.state.playbackRate} 
          getTimestamps={this.getTimestamps}
          changePlaybackRate={this.changePlaybackRate} 
          jumpToEventTimestamp={this.jumpToEventTimestamp}
          nextToLastTimestamp={this.state.nextToLastTimestamp}
          previousTime={this.state.previousTime}
          seekBarInputChanged={this.seekBarInputChanged}
          seekBarChanged={this.seekBarChanged}
          savedEventsObj={this.state.savedEventsObj} 
          eventTimestampToPlay={this.state.eventTimestampToPlay} 
          changeEvent={this.changeEvent} 
          successMessage={this.successMessage} />
        
        <div id="primary-view-container">
          <div className="row justify-content-sm-center">
            <div id="side-container" className="col-sm-3">
              <div id="secondary-view-1" className="video-container" onClick={this.resizeVideo}></div>
              <div id="secondary-view-2" className="video-container" onClick={this.resizeVideo}></div>
              <div id="secondary-view-3" className="video-container" onClick={this.resizeVideo}></div>
            </div>
            <div id="primary-view" className="col-sm-9 video-container" onClick={this.resizeVideo}></div>
          </div>
        </div>

        <div id="default-view-container" className="row">
            <div id="video-left-container" className="col-sm-4 video-container" onClick={this.resizeVideo}>
              <div id="video-left-holder" className="video-holder">
                <video id="video-left" autoPlay width="100%"></video>
                <div id="video-left-title" className="video-title">Left Repeater</div>
              </div>
            </div>
            <div id="video-front-container" className="col-sm-4 video-container" onClick={this.resizeVideo}>
              <div id="video-front-holder" className="video-holder">
                <video id="video-front" autoPlay width="100%"></video>
                <div id="video-front-title" className="video-title">Front</div>
              </div>
            </div>
            <div id="video-right-container" className="col-sm-4 video-container" onClick={this.resizeVideo}>
              <div id="video-right-holder" className="video-holder">
                <video id="video-right" autoPlay width="100%"></video>
                <div id="video-right-title" className="video-title">Right Repeater</div>
              </div>
            </div>
            <div id="video-rear-container" className="col-sm-6 offset-sm-3 video-container" onClick={this.resizeVideo}>
              <div id="video-rearview-holder" className="video-holder">
                <video id="video-rearview" autoPlay width="100%"></video>
                <div id="video-rearview-title" className="video-title">Rear View (if available)</div>
              </div>
            </div>
        </div>
        
        <div className="hide">
          <video id="video-temp" width="100"></video>
        </div>
      </div>
    );
  }
}

function App() {
  return (
    <div>
      <div><VideoContainer /></div>
    </div>
  );
}

export default App;
